import type { AxiosError } from "axios";
import type { User as FirebaseUser } from "firebase/auth";

import type { DisplayedParams, TranslateParams } from "../services/RimoVoiceBackend";
import type { NoteProps } from "../types";
import Sentry from "../utils/sentry";

import { client } from "./";

export interface ErrorResponse {
  error?: string;
  message: string;
  error_type?: string;
}
export interface VoiceInfoResponse {
  id: string;
  // team
  active_team_id: string; // ref: https://github.com/rimoapp/rimo-backend/pull/408
  personal_team_available: boolean; // ref: https://github.com/rimoapp/rimo-backend/pull/408
  // payments
  customer_id: string;
  card_registered: boolean;
  /**
   * 直近の課金に失敗したかどうか
   * @default false
   */
  last_charge_failed: boolean;
  // special plan
  trial_user: boolean;
  fixed_charge: boolean;
  // stats
  available_free_duration: number;
  charging_record_times: number;
  charging_upload_times: number;
  record_times: number;
  total_charging_duration: number;
  total_charging_record_duration: number;
  total_charging_upload_duration: number;
  total_duration: number;
  total_record_duration: number;
  total_upload_duration: number;
  upload_times: number;
  // feature flags
  collab_editor_used: boolean;
  collab_editor_enabled: boolean;
  document_editor_enabled: boolean;
  // note tab config
  note_tab_config: {
    focus_tab?: string;
  };
  // affiliate
  a8?: string;
  // for admin
  providers?: string[];
  last_login_at?: string;
  /**
   * 文字起こしを強制的に実施したことがあるか
   * @default false
   */
  exceeded_allowed: boolean;

  // feature flags
  only_writing_mode: boolean; // deprecated
  enable_writing_mode: boolean; // deprecated
  rimo_voice_disabled?: boolean;
  interviewer_enabled?: boolean;
  time_insight_enabled?: boolean;

  // locale
  locale: string;
  timezone: string;

  // claude coupon flag
  is_advanced_summary: boolean;
}

export const rimoVoiceEnabled = (voiceInfo?: VoiceInfoResponse) => {
  return !voiceInfo?.rimo_voice_disabled;
};

export interface AuthInfo {
  [key: string]: AuthProps;
}

export interface AuthProps {
  user_id: string;
  email: string;
  name: string;
  provider: string;
  provider_: string;
}

// duration=180000&media_type=audio&source=mic
export interface VoicePriceRequest {
  duration: number;
  source: string;
  filename?: string;
  media_type?: string;
  forbidden?: boolean;
}
// {"amount":0,"notice":"3分以下無料"}
export interface PriceInfo {
  amount: number;
  charging_amount: number;
  notice: string;
  paid_team_id: string;
  media_type?: string;
  forbidden?: boolean;
  error_code: "exceeded" | "upload_forbidden" | "";
}

type TranslatePrepareParams = Omit<TranslateParams, "paid_team_id">;
export interface TranslatePrepareResponse extends PriceInfo {
  note_id: string;
  duration: string;
}

export const initialPriceInfo: PriceInfo = {
  amount: 0,
  charging_amount: 0,
  notice: "",
  paid_team_id: "",
  error_code: "",
};

export interface ExternalMediaInfoRequest {
  target_url: string;
  password?: string;
}

export interface ExternalMediaInfoResponse {
  title: string;
  filename: string;
  duration: number;
  media_type: string;
  need_password: boolean;
}

export interface UploadExternalMediaRequest {
  download_url: string;
  media_type: string;
  dest_gs_url: string;
  password: string;
  paid_team_id: string;
  engine: string;
  title: string;
  displayed_duration: number;
  displayed_amount: number;
  displayed_charging_amount: number;
  displayed_notice: string;
  locale?: string;
  sub_locales?: string[];
  dictionary_id?: string;
  team_id?: string;
  channel_id: string | null;
  writing_mode?: boolean;
  custom_download_id: string;
}

export interface GetSharedNotesResponse {
  notes: NoteProps[];
  last_updated_at: string;
}

export interface GetViewedNotesResponse {
  notes: NoteProps[];
  last_note_view_updated_at: string;
}

export default {
  // 料金を取得する(URL,Fileも同一)
  getAboutPrice: async (req: VoicePriceRequest, rimoForceRefreshToken = true) => {
    const response = await client.get<PriceInfo>("/voice/price", {
      params: req,
      data: {
        rimoForceRefreshToken, // 毎回トークンをリフレッシュするか動的に判定
      },
    });

    return response.data;
  },

  // wma等のファイル長を取得できないファイルの料金算出
  // amount: 0
  // charging_amount: 0
  // duration: 11853
  // media_type: "audio"
  // note_id: "RSlrOIKVAvRbWIuFSeiO"
  // notice: "1分以下無料"
  // paid_team_id: ""
  prepareTranslate: async (params: TranslatePrepareParams): Promise<TranslatePrepareResponse> => {
    try {
      const response = await client.post<TranslatePrepareResponse>("/voice/translate/prepare", params);
      if (response.status !== 200) throw new Error("Occured Network error");
      return response.data;
    } catch (e) {
      Sentry.captureException(e, {
        extra: { error: e },
      });
      console.error("prepare_translate_error", e);
      throw e;
    }
  },
  // /voice/translate/accept/:noteID
  acceptTranslate: async (noteId: string, params: DisplayedParams): Promise<TranslatePrepareResponse> => {
    const response = await client.post<TranslatePrepareResponse>(`/voice/translate/accept/${noteId}`, params);
    if (response.status !== 200) throw new Error("Occured Network error");
    return response.data;
  },

  flagAsCollabEditorUsed: async (): Promise<true> => {
    const res = await client.post("/voice/info/collab_editor_used", {});
    if (res.status !== 200) throw new Error("Occured Network error");
    return true;
  },

  // URLでYoutube等の料金を算出
  externalMediaInfo: async (params: ExternalMediaInfoRequest): Promise<ExternalMediaInfoResponse> => {
    return client
      .get<ExternalMediaInfoResponse>("/voice/external_media/metadata", { params, timeout: 30000 })
      .then((res) => res.data)
      .catch((err: AxiosError<ErrorResponse>) => {
        const type = err.response?.data.error_type;
        switch (type) {
          case "WrongPassword":
            throw new Error("パスワードが間違っています。");
          case "UnsupportedURL":
            throw new Error("サポートされていないURLです。ファイルからのアップロードをご利用ください。");
          case "InvalidURL":
            throw new Error("URLが認識できません。URLを確認してください。");
          case "NoPermission":
            throw new Error(
              "RimoからURLへのアクセス権限がありませんでした。ファイルからのアップロードをご利用ください。"
            );
          case "NoLongerExists":
            throw new Error("指定されたURLにメディアが存在しません。ファイルをご確認ください。");
          default:
            Sentry.captureException(err, { extra: { backend_url: "/voice/external_media/metadata" } });
            throw new Error("システムエラーが発生しました。しばらく時間を置いて再度実施してください。");
        }
      });
  },

  uploadExternalMedia: async (params: UploadExternalMediaRequest) => {
    const resp = await client.post<NoteProps>("/voice/external_media/upload", params);
    return resp.data;
  },

  checkAuth: async (): Promise<AuthInfo> => {
    const res = await client.get<AuthInfo>("/voice/check_auth").catch((e: AxiosError) => {
      throw e;
    });
    if (res.status !== 200) throw new Error("Occured Network error");
    return res.data;
  },

  deleteUser: async (params: { category: string; detail: string; dryRun: boolean }) => {
    const resp = await client.delete<{
      // deleted: false
      deleted_card_count: number;
      deleted_note_count: number;
      total_notes_count: number;
    }>("/voice/users", {
      params: {
        reason_category: params.category,
        reason_detail: params.detail,
        dry_run: params.dryRun,
      },
    });

    return {
      card: resp.data.deleted_card_count,
      note: resp.data.deleted_note_count,
      totalNote: resp.data.total_notes_count,
    };
  },

  toggleCollabEditorEnabled: async (collabEditorEnabled: boolean) => {
    await client.post("/voice/info/collab_editor_enabled", {
      collab_editor_enabled: collabEditorEnabled,
    });
  },

  enableInterviewerOnSignUp: async (firebaseUser: FirebaseUser): Promise<void> => {
    const idToken = await firebaseUser.getIdToken();
    await client.post<void>(
      "/voice/info/feature_flags",
      {
        rimo_voice_disabled: true,
        interviewer_enabled: true,
      },
      {
        headers: {
          // apply jwt manually because axios interceptors doesn't setup yet
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  },

  enableInterviewerOnSignIn: async (firebaseUser: FirebaseUser): Promise<void> => {
    const idToken = await firebaseUser.getIdToken();
    await client.post<void>(
      "/voice/info/feature_flags",
      {
        interviewer_enabled: true,
      },
      {
        headers: {
          // apply jwt manually because axios interceptors doesn't setup yet
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  },
};
